<template lang="pug">
    construction-block.w-auto.flex.flex-col
        div
            autocomplete(
                :search="onSearchEvents"
                :placeholder="FORMS_LABELS.enter_event_name" 
                :get-result-value="getEventSearchResultValue"
                :debounce-time="1000"
                @submit="onSubmitEvent"
            )
            event-card(v-if="event.name" :name="event.name" :date_start="event.date_start" :date_end="event.date_end")
</template>

<script>
//Components
import ConstructionBlock from '@/components/common/block';
import TextInput from '@/components/common/forms/text-input';
import RegularButton from '@/components/common/buttons/regular-button';
import EventCard from './default-event-card.vue';
//Config
import { BUTTONS_VALUES, FORMS_LABELS } from '@/configs/names.js';
//Store
import { EventsNameSpace, EventsActionTypes, EventsGetterTypes, EventsMutationTypes } from '@/store/events/types';

export default {
    name: "DefaultUserSearch",
    components: {
        'construction-block': ConstructionBlock,
        'text-input': TextInput,
        'regular-button': RegularButton,
        'event-card': EventCard,
    },
    data() {
        return {
            event: {},
        }
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        events() {
            return this.$store.getters[`${EventsNameSpace}/${EventsGetterTypes.GetEvents}`]
        },
    },
    methods: {
        async onLoadEvents({ input }) {
            this.event = {}
            const query_params = {name: input}
            await this.$store.dispatch(`${EventsNameSpace}/${EventsActionTypes.GetEvents}`, { params: { ...query_params } })
        },
        async onSearchEvents(input) {
            await this.onLoadEvents({ input })
            return this.events
        },
        getEventSearchResultValue(result) {
            return result.name
        },
        onSubmitEvent({ name = '', date_start = '', date_end = '', _id = ''} = {}) {
            this.event = {
                name,
                date_start,
                date_end,
                id: _id,
            }
        },
    },
    beforeDestroy() {
        this.$store.commit(`${EventsNameSpace}/${EventsMutationTypes.SetEmptyEvents}`)
    },
    watch: {
        event: {
            handler(val) {
                this.$emit('value', val)
            },
            deep: true,
        }
    }
}
</script>