<template lang="pug">
    .container
        add-event
</template>

<script>
//Components
import AddEvent from '@/components/views/events/add-event'

export default {
    name: "AddEventPage",
    components: {
        'add-event': AddEvent,
    },
}
</script>