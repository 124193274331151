<template lang="pug">
    .container.w-full.border.rounded.p-3.shadow.mt-3
        span.block.text-sm {{name}}
        .flex.text-sm(v-if="date_start")
            span.block {{moment(date_start).format('DD MMMM YY')}} 
            span.block &nbsp;-&nbsp; 
            span.block {{moment(date_end).format('DD MMMM YY')}}
</template>

<script>
export default {
    name: "DefaultUserCard",
    props: {
        name: {
            type: String,
        },
        date_start: {
            type: Date,
        },
        date_end: {
            type: Date,
        }
    },
    computed: {
        from() {
            return this.$moment(this.date_start).format('DD MMMM YYYY')
        },
        to() {
            return this.$moment(this.date_end).format('DD MMMM YYYY')
        }
    }
}
</script>