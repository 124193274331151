<template lang="pug">
    div.p-3.border.w-full
        add-event-card
</template>

<script>
//Components
import AddEventCard from './components/add-event-card.vue'

export default {
    name: "AddEventComponent",
    components: {
        'add-event-card': AddEventCard,
    }
}
</script>