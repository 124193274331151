<template lang="pug">
    .container
        .text-xs.mb-2
            span {{ label }}
        slot
</template>

<script>
export default {
    name: "DefaultLabelWrapper",
    props: {
        label: {
            type: String,
        }
    }
}
</script>