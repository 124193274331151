<template lang="pug">
    construction-block.w-500.flex.flex-col
        text-input.mb-3(:label="FORMS_LABELS.list_name" @value="onName")
        label-wrapper(:label="FORMS_LABELS.parent_event")
            event-search(@value="onEvent")
        text-input.mb-3(:label="FORMS_LABELS.title" @value="onTitle")
        text-input.mb-3(:label="FORMS_LABELS.subtitle" @value="onSubtitle")
        label-wrapper.mb-3(:label="FORMS_LABELS.description")
            text-editor(
                :api-key="TINYMCE_API_KEY"
                v-model="form.description"
            )
        label-wrapper(:label="FORMS_LABELS.start_date_end_date")
            .flex.justify-between.mb-5
                date-picker(v-model="form.date_start" :placeholder="FORMS_LABELS.start_date")
                date-picker(v-model="form.date_end" :placeholder="FORMS_LABELS.end_date")
        label-wrapper(:label="FORMS_LABELS.add_participants")
            user-search(
                :show-user-card="false"
                @value="onUser" 
            )
            template(v-for="user in form.participants")
                user-card.mt-3(
                    :key="user.id"
                    :name="user.name"
                    :lastname="user.lastname"
                    :patronymic="user.patronymic"
                    :photo="user.photo" 
                    @remove="onRemoveUser(user)"
                )
        regular-button.mt-4(:value="BUTTONS_VALUES.save" :isActive="isSaveButtonActive" @click="onSave")
</template>

<script>
//Components
import ConstructionBlock from '@/components/common/block';
import UserSearch from '@/components/common/user/default-user-search';
import UserCard from '@/components/common/user/default-user-card';
import TextInput from '@/components/common/forms/text-input'; 
import LabelWrapper from '@/components/common/labels/default-label-wrapper';
import EventSearch from '@/components/common/events/default-event-search';
import RegularButton from '@/components/common/buttons/regular-button';
//Libs
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import Editor from '@tinymce/tinymce-vue'
// //Config
import { BUTTONS_VALUES, FORMS_LABELS } from '@/configs/names.js';
// //Store
import { EventsNameSpace, EventsActionTypes } from '@/store/events/types';
//Helpers
import { getUserAuthData } from '@/helpers/authorization';

export default {
    name: "AddEventCard",
    components: {
        'construction-block': ConstructionBlock,
        'user-search': UserSearch,
        'user-card': UserCard,
        'event-search': EventSearch,
        'date-picker': DatePicker,
        'text-input': TextInput,
        'text-editor': Editor,
        'label-wrapper': LabelWrapper,
        'regular-button': RegularButton,
    },
    data() {
        return {
            form: {
                name: '',
                parent: '',
                participants: [],
                date_start: '',
                date_end: '',
                information: {
                    title: '',
                    subtitle: '',
                    description: '',
                    files: [],
                }
            },
        }
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        isSaveButtonActive() {
            return this.form.name ? true : false
        },
        TINYMCE_API_KEY() {
            return process.env.VUE_APP_TINYMCE_API_KEY
        }
    },
    methods: {
        getUserAuthData() {
            return getUserAuthData()
        },
        onEvent(value) {
            this.form.parent = value.id
        },
        onName(value) {
            this.form.name = value
        },
        onTitle(value) {
            this.form.information.title = value
        },
        onSubtitle(value) {
            this.form.information.subtitle = value
        },
        onUser(value) {
            if (value && value.id)
                this.form.participants.push(value)
        },
        onRemoveUser({ id }) {
            const foundIndex = this.form.participants.findIndex(item => item.id === id)
            this.form.participants.splice(foundIndex, 1)
        },
        async onSave() {
            await this.$store.dispatch(`${EventsNameSpace}/${EventsActionTypes.AddEvent}`, { params: {
                    ...this.form, 
                    author: this.getUserAuthData().id,
                    participants: this.form.participants.map(item => item.id)
                } 
            })
        },
    },
}
</script>